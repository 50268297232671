import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RootState } from '../reducers';
import { loadProjectLinks } from '../reducers/data/data.actions';
import { map } from 'rxjs/operators';
import { selectProjectLinks, selectActiveProjectId } from '../reducers/data/data.selector';
import { ProjectLink } from '../@types/project-link';
import { Observable } from 'rxjs';
import { selectUrl } from '../reducers/router/router.selector';
import {Router, RoutesRecognized} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'az-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  navShow: boolean;
  navItems: Observable<ProjectLink[]> = this.store.pipe(map(selectProjectLinks));
  activeId: Observable<string> = this.store.pipe(map(selectActiveProjectId));


  constructor(private store:Store<RootState>, private router: Router, private location:Location) {}

  smileyUrl = '/about';
  initialUrl = this.location.path();
  
  

  ngOnInit() {
      if(this.initialUrl == '/about'){
        this.smileyUrl = '/';
        this.imgUrl = "assets/img/myface.png";
        this.changeClass = 'exie';
      }

    // console.log("CURRENT 1: ", this.location.path());

    this.router.events
            .pipe(filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            ).subscribe((e: any) => {
                // console.log("Current URL:", e[1].urlAfterRedirects); //current url
                // console.log("Previous URL:", e[0].urlAfterRedirects);// previous url
                if(e[1].urlAfterRedirects != '/about'){
                  this.smileyUrl = '/about';
                }
                else if(this.initialUrl == '/about') {
                  this.smileyUrl = '/';
                  console.log("THIS SHOULD BE WORKING");
                }  
                else {                  
                  this.smileyUrl = e[0].urlAfterRedirects;                  
                }
            });

    this.store.pipe(
      select(selectUrl),
      map((currentUrl) => {
        // console.log(currentUrl);
        
        if (currentUrl && currentUrl.indexOf('project') > -1) {
          return true; 
        }
        return false;
      })
    ).subscribe(
      (isProjPage) => {
        this.navShow = isProjPage;
        // if (this.navShow == true) {
        //   console.log('Yup we on a Project');
           
        // }
      }
    )    
    
  }

  
  imgUrl = 'assets/img/myface.png';
  changeClass = 'smiley';

  changeImg() {
    
    if (this.changeClass === 'smiley') {
        //this.imgUrl = "assets/img/x.svg";
        this.changeClass = 'exie';
    }
    else {
        //this.imgUrl = "assets/img/myface.png";
        this.changeClass = 'smiley';
    }
  }

  changeImg2() {
      this.imgUrl = "assets/img/myface.png";
      this.changeClass = 'smiley';
  }

  scrollToTop() {
    console.log("SHOULD SCROLL NOT REFRESH")
    document.getElementById("containerS").scrollTo(0,0);
  }

}
