import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAboutData } from '../reducers/data/data.actions';
import { RootState } from '../reducers';
import { AboutList } from '../@types/about-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAboutData } from '../reducers/data/data.selector';

@Component({
  selector: 'az-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  data: Observable<any> = this.store.pipe(map(selectAboutData));

  constructor(private store:Store<RootState>) { }  

   ngOnInit() {
  
    
     }
}
