import { Action, createReducer, on } from '@ngrx/store';
import { ProjectLink } from 'src/app/@types/project-link';
import { loadProjectLinks, loadProjectData } from './data.actions';
import { AboutList, AboutListItem } from 'src/app/@types/about-list';
import { loadAboutData } from './data.actions';


export const dataFeatureKey = 'data';

export interface State {
  projectLinks: ProjectLink[];  
  about: {
    components: AboutList[];
  };  
  projectDictionary: any;
}

export const initialState: State = {
  projectLinks: [],
  about: { components: [] },
  projectDictionary: {}
};

const dataReducer = createReducer(
  initialState,
  on(loadProjectLinks, (state, payload) => {
    // console.log(payload.projectLinks);
    return { 
      ...state, 
      projectLinks: payload.projectLinks
    };
  }),

  on(loadAboutData, (state, payload) => {
    return { 
      ...state, 
      about: payload,
    };
  }),

  on(loadProjectData, (state, payload) => {
    if(!payload.project || !payload.project.id){
      console.log(payload);
      return state;
    }
    return { 
      ...state, 
      projectDictionary: {
        ...state.projectDictionary,
        [payload.project.id]: payload.project
      }
    };
  }),

);

export function reducer(state: State | undefined, action: Action) {
  return dataReducer(state, action);
}
