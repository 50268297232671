import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutComponent } from './about/about.component';
import { ProjectPage1Component } from './project-page1/project-page1.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';


const routes: Routes = [
  {path: '', component: HomepageComponent, data: {animation: 'HomePage'}},
  {path: 'about', component: AboutComponent, data: {animation: 'AboutPage'}},
  {path: 'project/:projectId', component: ProjectPage1Component, data: {animation: 'ProjectPage'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), BrowserAnimationsModule, BrowserModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
