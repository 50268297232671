import { createAction, props } from '@ngrx/store';
import { ProjectLink } from 'src/app/@types/project-link';
import { AboutList, AboutListItem } from 'src/app/@types/about-list';

export const loadProjectLinks = createAction(
  '[Data] Load Project Links',
  props<{ projectLinks: ProjectLink[]; }>()
);

export const loadAboutData = createAction(
  '[Data] Load About Data',
  props<{ components: AboutList[]; }>()
);

export const loadProjectData = createAction(
  '[Data] Load Project Data',
  props<{ project: any; }>()
);




