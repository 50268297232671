import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { loadProjectLinks, loadProjectData, loadAboutData } from './reducers/data/data.actions';
import { RootState } from './reducers';
import { Store, select } from '@ngrx/store';
import { Router, NavigationEnd, NavigationStart} from '@angular/router';

import { slider} from './animations';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'az-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider
  ]
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('container', {static: false}) container: ElementRef;

  title = 'az-web'; 

  constructor(private http: HttpClient, private store:Store<RootState>, private router: Router) {  }

  ngAfterViewInit() {
    this.router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationEnd){
        this.container.nativeElement.scrollTop = 0;
      }

      // console.log(this.router);
  });
  }

  ngOnInit() {
    this.http.get("assets/data/app-config.json").subscribe((response: any) => {
      this.store.dispatch(loadProjectLinks(response));
      response.projectLinks.forEach((projectLink, index) => {
        this.http.get(`assets/data/projects/${projectLink.id}.json`).subscribe((projectResponse: any) => {
          if (projectResponse) {
            const nextProject = response.projectLinks[index+1];
            const cleanProject = {
              ...projectResponse,
              nextProjectId: (nextProject) ? nextProject.id : null,
            }
            // console.log("Here is the nextProject", nextProject);
            this.store.dispatch(loadProjectData({project: cleanProject}));
          }            
        })
      })
    })

    this.http.get("assets/data/about.json").subscribe((response: any) => {
      this.store.dispatch(loadAboutData(response));
      // console.log("DATA thing", response);
    })
  }
  
  prepareRoute(outlet: RouterOutlet) {
    //console.log("Outlet THING: ", outlet.activatedRoute.snapshot.params);
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  
  onActivate(event){
    console.log("EVENT THING:", event)
    // el.scrollTop = 0
  }

}




