import { createSelector, createFeatureSelector } from '@ngrx/store';
import { RootState } from '..';
import { State } from './data.reducer';
import { selectRouteId } from '../router/router.selector';

export const selectDataState = createFeatureSelector<RootState, State>('data');

export const selectProjectLinks = createSelector(
    selectDataState,
    (state: State) => {
        return state.projectLinks;
    }
);

export const selectAboutData = createSelector(
    selectDataState,
    (state: State) => {
        return state.about;
    }
);

export const selectActiveProject = createSelector(
    selectDataState,
    selectRouteId,
    (state: State, projectId: string) => {
        if (!projectId) {
            return {};
        }
        const cleanId = projectId.toLowerCase().replace('-', '')
        let project = state.projectDictionary[cleanId];
        if(project && state.projectDictionary[project.nextProjectId]) {
            project = {
                ...project,
                nextProject: state.projectDictionary[project.nextProjectId]
            }
        }
        return project;
    }
);

export const selectActiveProjectId = createSelector(
    selectActiveProject,
    (project: any) => {
        if(project){
            return project.id;
        }
        else {
            return '';
        }            
    }
    
);