import { reduce } from 'lodash';
import { TweenMax } from 'gsap';

export class SvgDistortFilter {
    DOM: {
        svg: SVGElement;
        menu: HTMLOListElement;
        imgs: HTMLImageElement[];
        menuLinks: HTMLAnchorElement[];
    };
    winsize: {
        width: number;
        height: number;
    };
    mousePos: {
        x: number;
        y: number;
    };
    lastMousePos: {
        translation: {
            x: number,
            y: number
        },
        displacement: {
            x: number,
            y: number
        }
    };
    dmScale: number;
    current: number;
    
    fade: boolean;
    feDisplacementMapEl = document.querySelector('feDisplacementMap');
    
    
    
    constructor() {
        const svg = document.querySelector<SVGElement>('svg.distort');
        const menu = document.querySelector<HTMLOListElement>('ol.navList2');
        this.DOM = {
            svg,
            menu,
            imgs: reduce(svg.querySelectorAll('g > image'), (accumulator, currentVal) => {
                accumulator.push(currentVal);
                return accumulator;
            }, [] ),
            menuLinks: reduce(menu.querySelectorAll('.menu__link'), (accumulator, currentVal) => {
                //console.log("THIS IDK2: ", accumulator)
                accumulator.push(currentVal);
                return accumulator;
            }, [] )            
        };

        this.calcWinsize();

        this.mousePos = {
            x: this.winsize.width/2,
            y: this.winsize.height/2            
        };

        this.lastMousePos = {
            translation: {
                x: this.winsize.width/2,
                y: this.winsize.height/2
            },
            displacement: {
                x: 0,
                y: 0
            }
        };
        this.dmScale = 0;
        this.current = -1;

        this.initEvents();
        this.render();
        
    }
    lerp = (a,b,n) => {
        return (1 - n) * a + n * b;
    }

    distance = (x1,x2,y1,y2) => {
        var a = x1 - x2;
        var b = y1 - y2;
        return Math.hypot(a,b);
    }

    getMousePos = (e: any) => {
        let posx = 0;
        let posy = 0;
        if (!e) e = window.event;
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY) 	{
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        const coords = { x : posx+250, y : posy+300 };
        return coords;
    }

    calcWinsize() {
        this.winsize = {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    initEvents() {
        window.addEventListener('mousemove', ev => this.mousePos = this.getMousePos(ev));        

        this.DOM.menuLinks.forEach((item, pos) => {

            const mouseenterFn = () => {
                if ( this.current !== -1 ) {
                   TweenMax.set(this.DOM.imgs[this.current], {opacity: 0});
                }
                this.current = pos;
                if ( this.fade ) {
                    TweenMax.to(this.DOM.imgs[this.current], 0.5, { opacity: 1});
                    this.fade = false;
                }
                else {
                    TweenMax.set(this.DOM.imgs[this.current], {opacity: 1});
                }                
            };
            item.addEventListener('mouseenter', mouseenterFn);
            //console.log("TEST: ", mouseenterFn);
        });

        const mousemenuenterFn = () => this.fade = true;
        const mousemenuleaveFn = () => TweenMax.to(this.DOM.imgs[this.current], .2, { opacity: 0});
            
        this.DOM.menu.addEventListener('mouseenter', mousemenuenterFn);
        this.DOM.menu.addEventListener('mouseleave', mousemenuleaveFn);
    }
    render() {
        this.lastMousePos.translation.x = this.lerp(this.lastMousePos.translation.x, this.mousePos.x, 0.1);        
        this.lastMousePos.translation.y = this.lerp(this.lastMousePos.translation.y, this.mousePos.y, 0.1);
        this.DOM.svg.style.transform = `translateX(${(this.lastMousePos.translation.x-this.winsize.width/2)}px) translateY(${this.lastMousePos.translation.y-this.winsize.height/2}px)`;
        
        // Scale goes from 0 to 100 for mouseDistance values between 0 to 100
        this.lastMousePos.displacement.x = this.lerp(this.lastMousePos.displacement.x, this.mousePos.x, 0.1);
        this.lastMousePos.displacement.y = this.lerp(this.lastMousePos.displacement.y, this.mousePos.y, 0.1);
        const mouseDistance = this.distance(this.lastMousePos.displacement.x, this.mousePos.x, this.lastMousePos.displacement.y, this.mousePos.y);
        this.dmScale = Math.min(mouseDistance, 100);  
        this.feDisplacementMapEl.scale.baseVal = this.dmScale;

        requestAnimationFrame(() => this.render());
    }

}

