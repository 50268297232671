import {trigger, animate, style, group, animateChild, query, transition} from '@angular/animations';

const optional = { optional: true };

const easing = "cubic-bezier(0.230, 1.000, 0.320, 1.000)"; // east out quint

export const slider =
  trigger('routeAnimations', [
    transition('* => AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], optional),
      query(':enter', [
        style({ 
          left: '-100%',
        })
      ], optional),
      query(':leave', [
        style({
          height: '100%'
        })
      ], optional),
      group([
        query(':leave', [  //this is the one that leaves 
            animate(`0ms ${easing}`, style({ left: '0%' }))
        ], optional),
        query(':enter', [  //this is the one that enters
            style({zIndex: '2'}),
            animate(`1200ms ${easing}`, style({ left: '0%' }))
        ], optional)
      ]),
      query(':enter', animateChild(), optional),
    ]),
    transition('AboutPage => *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
          })
        ], optional),
        query(':enter', [
          style({ 
            left: '-100%',
            height: '100%'
          })
        ], optional),
        query(':leave', animateChild(), optional),
        group([
          query(':leave', [  //this is the one that leaves
            style({zIndex: '2'}),
            animate(`1200ms ${easing}`, style({ left: '-100%'}))
          ], optional),
          query(':enter', [  //this is the one that enters
            animate(`0ms ${easing}`, style({ left: '0%'}))
          ], optional)
        ]),
        query(':enter', animateChild(), optional),
      ]),
    transition('HomePage => ProjectPage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',            
          })
        ], optional),
        query(':enter', [
          style({ 
              top: '100%',
              opacity: '0'
            })
        ], optional),
        query(':leave', [
          style({
              height: '100%',
              pointerEvents: 'none'
            })
        ], optional),
        query(':leave', animateChild(), optional),
        group([
          query(':enter', [  //this is the one that enters
            style({zIndex: '2'}),
            animate(`933ms ${easing}`, style({ top: '0%', opacity: '1'}))
          ], optional)
        ]),
        query(':enter', animateChild(), optional),
      ]),
    transition('ProjectPage => HomePage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
          })
        ], optional),
        query(':enter', [
          style({ 
              top: '0%',
              opacity: '1',
              height: '100%'
            })
        ], optional),
        query(':leave', animateChild(), optional),
        group([
          query(':leave', [  //this is the one that leaves
            style({zIndex: '2'}),            
            animate(`933ms ${easing}`, style({ top: '100%', opacity: '0' }))
          ], optional),          
        ]),
        query(':enter', animateChild(), optional),
      ])
  ]);

