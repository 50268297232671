import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import * as fromData from './data/data.reducer';

export interface RootState {
  router: fromRouter.RouterReducerState<any>;
  data: fromData.State;
}

export const reducers: ActionReducerMap<RootState> = {
  router: fromRouter.routerReducer,
  data: fromData.reducer,
};


export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [] : [];
