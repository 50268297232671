import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProjectLink } from '../@types/project-link';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { RootState } from '../reducers';
import { map } from 'rxjs/operators';
import { selectProjectLinks } from '../reducers/data/data.selector';
import { SvgDistortFilter } from '../helpers/demo2';


@Component({
  selector: 'az-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {

  svgDistortFilter: SvgDistortFilter;

  navItems: Observable<ProjectLink[]> = this.store.pipe(map(selectProjectLinks));

  constructor(private store: Store<RootState>) { }

  ngAfterViewInit() {
    this.navItems.subscribe(() => {
      if(this.svgDistortFilter){
        return; 
      }
      const menu = document.querySelector<HTMLOListElement>('ol.navList2');
      if(menu.querySelectorAll('.menu__link').length > 0) {
        this.svgDistortFilter = new SvgDistortFilter();
        // console.log("This SVG THING: ", this.svgDistortFilter);
      }
      
      
    })
    }


  ngOnInit() { }


  activeItemIndex = -1;

  homePageHover(index) {
    this.activeItemIndex = index;
  }

  removePageHover() {
    this.activeItemIndex = -1;
  }

}
