import { Component, OnInit, HostBinding, SecurityContext, Pipe, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { RootState } from '../reducers';
import { Observable } from 'rxjs';
import { selectActiveProject } from '../reducers/data/data.selector';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform } from '@angular/core';

@Component({
  selector: 'az-project-page1',
  templateUrl: './project-page1.component.html',
  styleUrls: ['./project-page1.component.scss']
})
export class ProjectPage1Component implements OnInit {

  project: Observable<any> = this.store.pipe(map(selectActiveProject));
  // subs: Subscription;


  constructor(private store:Store<RootState>, private router: Router, private sanitizer: DomSanitizer) {
    // this.subs = this.project.subscribe();
   }

  ngOnInit() {
  }

  myCoolStyle(object: any) {
    const string = `--height-mobile: ${object.mobile}px; --height-tablet: ${object.tablet}px; --height-desktop: ${object.desktop}px;`
    return this.sanitizer.bypassSecurityTrustStyle(string);
  }  
  
}


@Pipe({ name: 'safe' })
  export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
    transform(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
 

